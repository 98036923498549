//new slideshow
.bdh-fiddler-slideshow--slides {
    .slideshow--slide {
        border: 1px solid #ccc;
        padding: 4px;
    }
    
}
.bdh-fiddler-slideshow--nav {
        border: 1px solid #ccc;
        padding: 4px;
    margin-top: 1rem;
        background: #ccc;
    .bdh-fiddler-slideshow--slide-caption {
        display: none;
    }

   .slideshow--slide {

       padding: 0 2px;
       img {
       }
    }
}


.bdh-fiddler-slideshow {

    width: 600px;
    margin: 0 auto;
}
.bdh-fiddler-slideshow--slides {
    img {
        width: 100%;
    }
}













#flexslider-1 {
	// width: 50%;
	// margin: 0 auto;
	// border: 1px solid #ccc;
	// color: #fff;
	// background: $rust;

	.slides li {
		border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
	}
	.flex-direction-nav a {
		text-decoration: none;
		display: block;
		width: 40px;
		height: 40px;
		margin: -5px 0 0;
		position: absolute;
		top: 21%;
		// top: 175px;
		z-index: 10;
		overflow: initial;
		// opacity: 0;
		cursor: pointer;
		color: rgba(0, 0, 0, 0.8);
		text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
	.flex-control-nav li {
		// height: 95px;
		// overflow: hidden;
	}
	// this makes the controls zoom in 
	.flexslider .flex-direction-nav a, .flexslider .flex-direction-nav a {
		display: initial;
	}
}

@include media(800px) {
	.flexslider .slides {
		width: 50%;
		float: left;
		margin-right: 10px;
	}
	.flex-control-thumbs {
		width: 40%;    /* margin-left: 10px; */
	}
	.flex-direction-nav {
		width: 50%;
	}
	.flex-direction-nav .flex-next {
		margin-right: 50% !important;
	}
}
