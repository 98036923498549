
.has-sidebar-first .l-main {
	background: $rust;
}

.l-content {
	background: #fff;
	h2 {
		// text-transform: uppercase;
		text-align: center;
		font-size: 2em;
		font-weight: normal;
	}
}
.l-main {
padding-left: 0px !important;
padding-right: 0px !important;
}

.l-header, .l-main, .l-footer {
margin-bottom: 10px !important;
}
