dl.solr-thumb {
	float: none;
	width: 94px;
	@include media($phone) {
		float: left;
		width: 15%;
	}
}

dl.solr-fields {
	float: none;
	width: 100%;
  @include media($phone) {
  	float: right;
    width: 83%;}
}

.islandora.islandora-solr-search-results {
  font-family: $cuprum;
  line-height: 1.4;
}
