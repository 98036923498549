.header_menu {
  .tb-megamenu .nav li.dropdown.active > .dropdown-toggle {
    background-color: $rust;
  }
  li.searchbar {
    display: none;
  }
  @include media($phone) {
     li.searchbar {
    display: block;
  }
    .nav > li > a > i {
    display: none;
  }
  .nav > li > a > i.fa-search {
    display: block;
  }
  .nav > li > a > i.fa-search:after {
    display: none;
  }
  .searchbar span {
    display: none;
  }
  .tb-megamenu .nav > li {
    float: left;
    margin-left: 0;
    width: 16%;
  }
  .tb-megamenu {
    margin-bottom: 0px;

    .nav > li > a {
      padding: 32px 16px 41px;
    }
    .nav > li.searchbar > a {
      padding-bottom: 43px;
    }
  }
  .tb-megamenu-submenu.dropdown-menu.mega-dropdown-menu.nav-child {
    width: 101.4% !important;
  }
  .tb-megamenu .nav li.dropdown.open.active > .dropdown-toggle, .tb-megamenu .nav > li.dropdown.open.active > a:hover {
    background-color: $tan;
    border-color: #444;
    color: #eee;
  }
  }
}

.header_menu .searchbar.menu input[type="text"] {
    margin-top: 10px;
  margin-bottom: 0px;
}

.tb-megamenu .traditions, .tb-megamenu .styles, .tb-megamenu .events{
  .menu .expanded, .menu .collapsed {
    list-style-image: none;
    list-style-type: none;
  }
  h2 {
    color: #fff;
    text-align: center;
    font-size: 1.5em;
    font-weight: normal;
  }
  ul {
    .active-trail > a {
      color: white;
    }
    a {
      color: $tan;
      text-transform: uppercase;
      font-size: .9em;
      &:hover {
        color: #fff;
      }
    }
  }
  ul ul li {
    margin-left: 2em;
    a {
      color: #333;
    }
    a:hover {
      color: #fff;
    }
    a.active {
      color: #fff;
    }
  }

}

.header-menu .tb-megamenu .dropdown-menu li > a {
  color: $rust;
}

