// .page-fiddlers header.l-header, 
// .page-islandora header.l-header,
// .page-filters header.l-header  {
// background: $rust;
// }

// header.l-header  {
// background: $rust;
// }
nav#block-system-main-menu a {
color: #fff;
}
.l-branding {
	background: $rust;
	.site-logo img {
padding-top: 10px;
}
}
@include media(0 $phone) {
	.l-branding {
  padding: 10px 10px 0;
  border-bottom: 4px solid #fff;
}
}

@include media($phone) {
	.page-islandora .l-header, .page-filters .l-header, .page-fiddlers .l-header {
		padding: 10px 10px 0px 10px;
	}
	header.l-header {
		background: $rust;
		padding: 10px 10px 0px 10px;
	}
	.l-region--navigation {
		display: none;
	}
}

/* ==========================================================================
   people pages
   ========================================================================== */
.people {
	.col-left {
		.islandora-object-image {
			border: 1px solid #ccc;
			padding: 5px;
			margin-bottom: 1em;
			@include border-radius(4px);
		}
		img {
			width: 100%;
			display: block;
		}
		fieldset {
			background-color: $rust;
			border: 1px solid #ddd;
			margin: 0 0 0.75em;
			padding: 1.5em;
			color: $tan;

			legend {
				display: none;
			}
		}
	}
	.biography {
		.bio {
			display: none;
		}
	}
}
@include media($phone) {
	.people {
		@include add-grid(12);

		.col-left {
			@include grid-span(6, 1);
		}
		.biography {
			@include grid-span(6, 7);
		}
	}
}

.people {
	.islandora-inline-metadata {
	font-family: $cuprum;
	font-size: 1.2em;
	line-height: 1;
	dt {
		width: 40%;
		color: $tan;
		padding-top: 20px;
	}
	dd {
		width: 60%;
		color: #fff;
		padding-top: 20px;
		
		a {
			color: $tan;
			&:hover {
				color: #fff;
				text-decoration: underline;
			}
		}
	}
	
}
.islandora-metadata dt.first, .islandora-metadata dd.first {
	padding-top: 0px;
	border-top: 0;
}
}

/* ==========================================================================
   views slideshow - people pages
   ========================================================================== */	
.views-slideshow-controls-bottom {
		width: 100%;
	@include media($phone) {
		width: 48%;
		float: left;
		margin-left: 10px;
	}
}

.view-islandora-related-images {
	margin-bottom: 20px;
	@include clearfix;
}

div#views_slideshow_cycle_main_islandora_related_images-block {
    
    height: 480px;
    overflow: hidden;
    @include media($phone) {
		width: 45%;
		float: left;
		margin-left: 10px;
	}
}

.views_slideshow_cycle_slide {
    width: 100% !important;
}

.views_slideshow_cycle_slide img {
    width: 100%;
}
.views_slideshow_pager_field div {
    display: inline-block;
}

.views-slideshow-cycle-main-frame {
    width: 100% !important;
}
.views-slideshow-pager-field-item {
    width: 24%;
    height: 90px;
    overflow: hidden;
}
@include media(800px) {
	.tune_snippets {
    clear: both;
  margin-top: 20px;
  width: 50%;
  float: left;
  
}
.oral_histories {
    width: 49%;
    float: right;
     margin-top: 20px;
}
.panel-pane.pane-views.pane-islandora-is-speaker-in-audio {
    width: 49%;
    float: right;
    padding-top: 20px;
}
.pane-views-video-blocks-block-1 {
    clear: both;
}

}

h2.pane-title {
  margin-bottom: 20px;
  // margin-top: 20px;
}
.video-js {
	width: 100%	!important;
}

/* ==========================================================================
   video clips under the fiddlers
   ========================================================================== */
.view-video-blocks { width: 100%;
	.video-js { width: 100% !important; }
	.views-field-fgs-label-s { font-size: 1.2em; line-height: 1.2; text-align: center; }
	.views-field-mods-note-ms { text-align: center; margin: 10px 0; }
	@include media($phone) { .views-row { width: 48%; }
		.views-row-odd { float: left; }
		.views-row-even { float: right; }
		.views-field-fgs-label-s { font-size: 1.3em; line-height: 1.4; text-align: left; }
		.views-field-mods-note-ms { text-align: left; }
		.views-row-first.views-row-last { width: 552px; margin: 0 auto; float: none; }
	}
}

/* ==========================================================================
   event page videos
   ========================================================================== */
.page-islandora-object-bdh2587 .video-js,
 .page-islandora-object-bdh2586 .video-js,
  .page-islandora-object-bdh2584 .video-js,
  .page-islandora-object-bdh2602 .video-js  {
  width: 100% !important;
  // height: 390px !important;
  // margin-top: 60px;
}
table.views-table.cols-0 {
  font-family: cuprum;
  line-height: 1.3;
}


/*=========================================================
=            islandora metadats display tweaks            =
=========================================================*/

.islandora-video-metadata, .islandora-large-image-metadata {
   
    border: 1px solid #ccc;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    margin: 0 auto;
    margin-bottom: 10px;
    @include media($phone) {width: 50%;}
}

.islandora-large-image-metadata {
	margin-top: 20px;
	.islandora-solr-metadata-sidebar {
		h2 {
			display: none;
		}
		p {
			font-size: 1.5em;
			color: $rust;
			text-align: center;  /* border-bottom: 1px solid; */
		}
	}
}

.panel-pane.pane-block.pane-views-c18c4922179df5524ee9f64ecef5c199 {
  
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    margin: 0 auto;
    padding: 5px;
    @include media($phone) {width: 50%;}
}

/*-----  End of islandora metadats display tweaks  ------*/


