.l-region--sidebar-first {
	padding: 1em;
	background: $rust;
}

.sidebar-nav {
	.menu .expanded, .menu .collapsed {
		list-style-image: none;
		list-style-type: none;
	}
	h2 {
		color: #fff;
		text-align: center;
		font-size: 1.5em;
		font-weight: normal;
	}
	ul {
		.nolink {
			color: $tan;
			text-transform: uppercase;
			font-size: .9em;
		}
		.active-trail > a {
			color: white;
		}
		a {
			color: $tan;
			text-transform: uppercase;
			font-size: .9em;
			&:hover {
				color: #fff;
			}
		}
	}
	ul ul li {
		margin-left: 2em;
		a {
			color: #333;
		}
		a:hover {
			color: #fff;
		}
		a.active {
			color: #fff;
		}
	}
}