/*============================================
=            one image/ one video            =
============================================*/

.page-islandora-object-bdh313 {
	.view-video-blocks {
		@include media($phone) { 
		.views-row { width: 700px;
		margin: 0 auto; }
		.views-row-odd { float: none; }
		.views-row-even { float: none; }
	}
	}
}

/*-----  End of one image/ one video  ------*/

