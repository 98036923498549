@mixin flash($color) {
background: $color;
color: darken($color, 60);
font-weight: bold;
margin-bottom: $base-line-height / 2;
padding: $base-line-height / 2;

a {
color: darken($color, 70);

&:hover {
color: darken($color, 90);
}
}
}




%flash-error {
  @include flash($error-color);
}

%flash-notice {
  @include flash($notice-color);
}

%flash-success {
  @include flash($success-color);
}

// .messages.error {
//   @include flash($error-color);
// }

// .messages.warning {
//   @include flash($notice-color);
// }

// .messages.status {
//   @include flash($success-color);
// }

.messages {
	border: none;
	&.error {
	  @include flash($error-color);
	}

	&.warning {
	  @include flash($notice-color);
	}

	&.status {
	  @include flash($success-color);
	}
}