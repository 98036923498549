
// Typography
@import url(//fonts.googleapis.com/css?family=Gabriela|Amaranth|Cuprum);

// font-family: 'Gabriela', serif;
// font-family: 'Amaranth', sans-serif;
// font-family: 'Cuprum', sans-serif;

$base-font-family: 'Gabriela', serif;
$heading-font-family: 'Cuprum', sans-serif;
$cuprum: 'Cuprum', sans-serif;
$amaranth: 'Amaranth';
$gabriela: 'Gabriela', serif;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #477dca;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
$light-red: #FBE3E4;
$light-yellow: #FFF6BF;
$light-green: #E6EFC2;
$rust: #995051;
$tan: #D2966E;
$gray: #918B8C;

// Font Colors
$base-background-color: #fff;
$base-font-color: $dark-gray;
$action-color: $rust;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

//  _____ _           _                 _                
// |  ___| | __ _ ___| |__     ___ ___ | | ___  _ __ ___ 
// | |_  | |/ _` / __| '_ \   / __/ _ \| |/ _ \| '__/ __|
// |  _| | | (_| \__ \ | | | | (_| (_) | | (_) | |  \__ \
// |_|   |_|\__,_|___/_| |_|  \___\___/|_|\___/|_|  |___/

$error-color: $light-red;
$notice-color: $light-yellow;
$success-color: $light-green;


// Breakpoints

$phone: 600px;
