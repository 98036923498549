//@format
footer.l-footer {
    background: none;
    margin-top: 50px;
    padding: 0 25px;
}

.l-region--footer {
    padding: 0 20px;
}

.footer_logos {
    //display: none;
    max-width: 1140px;
    margin: 0 auto;
    img {
        width: 100%;
    }
    .islandora_logo,
    .cmh_logo,
    .upei_logo {
        width: 230px;
        margin: 0 auto;
        margin-bottom: 31px;
    }
    // debug mode switch
    // @include sgs-change('debug', true);
    @include media($phone) {
        @include add-grid(3);
        padding: 10px;
        // @include background-grid($color: #ccc);

        .islandora_logo {
            @include grid-span(1, 1);
            text-align: left;

            img {
                width: 90%;
                margin-top: 5px;
            }
        }
        .cmh_logo {
            @include grid-span(1, 3);
            padding-top: 10px;
        }
        .upei_logo {
            @include grid-span(1, 2);
            padding-top: 3px;
            text-align: center;

            img {
                width: 162px;
            }
        }
        @include clearfix;
    }
}
.islandora-link {
    margin-bottom: 30px;
}
.footer_menu {
    display: none;
    @include media($phone) {
        display: block;
        clear: both;
        width: 518px;
        margin: 10px auto 20px;
        ul.menu {
            @include horizontal-list;
            li {
                border-right: 1px solid $rust;
                padding: 0 10px;
                &:last-child {
                    border-right: 0px;
                }
            }
            li a {
                font-family: $amaranth;
                text-transform: uppercase;
                font-size: 0.7em;
                color: $rust;
            }
        }
        .islandora-link {
            margin-bottom: 30px;
        }
    }
}
